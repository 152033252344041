import { createContext, useContext, useState } from "react";
const appContext = createContext(null);

const AppProvider = ({ children }) => {
    const titleChange = (str) => {
        return document.title = str;
    }
    return (
        <appContext.Provider value={{ titleChange }}>{children}</appContext.Provider>
    )
}

const useGlobalHook = () => useContext(appContext);
export { AppProvider, useGlobalHook };