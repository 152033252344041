import React, { useState } from 'react';
import './style.css';
import Carousel from 'better-react-carousel'

const Contributers = () => {
    const initialContributors = [
        {
            name: 'Ramesh Kulkarni',
            Quotes: 'A remarkable effect! ',
            details: "“Matricula is doing phenomenal work in promoting literacy among underprivileged children. Their commitment to providing books and educational support is truly commendable. I look forward to seeing the continued growth and success of children through such initiatives in the years to come.”",
            image: '/assets/contributers/Ramesh-Kulkarni.png',
        },
        {
            name: 'Saket Sureka',
            Quotes: ' To provide technical knowledge! ',
            details: "“Matricula education is an invaluable partner in bridging the digital divide for underprivileged children. Their efforts in providing technology education are making a significant impact. We look forward to the foundation's continued growth and success in the field of digital literacy.”",
            image: '/assets/contributers/Saket-Sureka.png'
        },
        {
            name: 'Ramesh Agarwal ',
            Quotes: 'Empowering young mathematicians! ',
            details: "“Math Mavericks for Miners is playing a vital role in empowering underprivileged children through mathematics education. Their dedication to promoting mathematical skills is truly inspiring.”            ",
            image: '/assets/contributers/Ramesh-Agarwal.png'
        },
        {
            name: 'Mohan Ram ',
            Quotes: ' Fostering creative expression!',
            details: "“This initiative is a beacon of hope for underprivileged children looking for a creative outlet. The organization's commitment to fostering artistic expression is making a real difference. I'm excited to see how ‘Embracing Literacy with Love’ continues to inspire young artistic talents and will continue to nurture them.”",
            image: '/assets/contributers/Mohan-Ram.png'
        },
        {
            name: 'Sunita Chatterjee',
            Quotes: 'Harmony in education!',
            details: "“Vocational Virtue Aid is creating harmony in education for underprivileged children through music programs. Their dedication to enriching young minds through music education is truly commendable.”",
            image: '/assets/contributers/Sunita-Chatterjee.png'
        },
        {
            name: 'Rupa Kapoor',
            Quotes: ' Igniting scientific curiosity!',
            details: "“Science Saviors for Scholars is making a remarkable impact in sparking scientific curiosity among underprivileged children. Their commitment to promoting science education is admirable.”",
            image: '/assets/contributers/Rupa-Kapoor.png'
        },
        {
            name: 'Suresh Lakhotia',
            Quotes: 'Master multilingualism!',
            details: "“The Vocational Virtue Aid is playing a vital role in helping disadvantaged children master multiple languages. Their dedication to linguistic education is making a tangible impact.”",
            image: '/assets/contributers/Suresh-Lakhotia.png'
        },
        {
            name: 'Ahana Sengupta',
            Quotes: 'Empowering the coders of the future!',
            details: "“Tender Minds Tutoring Program is empowering underprivileged children with essential coding skills. Their commitment to fostering a foundation in technology is admirable.”",
            image: '/assets/contributers/Ahana-Sengupta.png'
        },
        {
            name: 'Aman Kumar',
            Quotes: 'Building Resilient Athletes!',
            details: "“Spark of Sportsmanship is building resiliency and character among underprivileged children through sports education. Their dedication to promoting physical fitness and teamwork is truly admirable.”",
            image: '/assets/contributers/Aman-Kumar.png'
        },
        {
            name: 'Omkarnath Trivedi',
            Quotes: 'Development of environmental management!',
            details: "“Green Guardians for Growth is inculcating a sense of environmental conservation among underprivileged children. Their commitment to environmental education is making a positive impact.”",
            image: '/assets/contributers/Omkarnath-Trivedi.png'
        },
    ];

    const [contributors, setContributors] = useState(initialContributors);

    return (
        <>
            <div className='contributers-background'>
                <div className='contributers-content'>
                    <h1 className='contributer-header'>Contributers</h1>
                    <Carousel cols={3} rows={1} gap={10} loop>
                        {contributors.map((contributor, index) => (
                            <Carousel.Item key={index}>
                                <div className='single-grid'>
                                    <img src={contributor.image} alt='pic' className='image-style-contributers' loading='lazy'/>
                                    <div className='name-and-donation-amount'>
                                        <h1>{contributor.name}</h1>
                                        <p>Donated {contributor.quotes}</p>
                                    </div>
                                    <div className='details-text'>
                                        <p>{contributor.details}</p>
                                    </div>
                                </div>
                            </Carousel.Item>
                        ))}
                    </Carousel>
                </div>
            </div>
        </>
    );
}

export default Contributers;


