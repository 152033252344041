




import React, { useState } from 'react';
import { NavLink } from 'react-router-dom'
import { HiBars4 } from "react-icons/hi2";
import { FaCaretDown, FaCaretUp } from "react-icons/fa6";
import { MdCancelPresentation } from "react-icons/md";
import './style.css';

const subHeaderMenu = [
    {
        label: "All",
        link: "https://matricula.co.in/students-corner/"
    }
    , {
        label: "Biology",
        link: "https://matricula.co.in/category/blog/biology/"
    }, {
        label: "Chemistry",
        link: "https://matricula.co.in/category/blog/chemistry/"
    }, {
        label: "English",
        link: "https://matricula.co.in/category/blog/english/"
    }, {
        label: "Geography",
        link: "https://matricula.co.in/category/blog/geography/"
    }, {
        label: "History",
        link: "https://matricula.co.in/category/blog/history/"
    }, {
        label: "Maths",
        link: "https://matricula.co.in/category/blog/maths/"
    }, {
        label: "Physics",
        link: "https://matricula.co.in/category/blog/physics-blog/"
    }, {
        label: "NCERT",
        link: "https://matricula.co.in/category/blog/ncert/"
    },
]


const MatriculaNavbar = () => {

    const [isShowHover, setIsShowHover] = useState(false)
    const [isDropdownShow, setIsDropDownShow] = useState(false);
    const [isDropdownShow2, setIsDropDownShow2] = useState(false);
    const [isMobileNavShow, setIsMobileNavShow] = useState(false);
    const toggleNav = () => setIsMobileNavShow(!isMobileNavShow);
    const navItems = [
        {
            to: "https://mantra.matricula.co.in/", label: "MANTRA", onMouseEnter: () => {
                setIsShowHover(false)
            }
        },
        {
            to: "https://mts.matricula.co.in/", label: "MTS", onMouseEnter: () => {
                setIsShowHover(false)
            }
        },
        {
            to: "https://spokenenglish.matricula.co.in/", label: "Spoken English", onMouseEnter: () => {
                setIsShowHover(false)
            }
        },
        {
            to: "https://courses.matricula.co.in/", label: "Courses", onMouseEnter: () => {
                setIsShowHover(false)
            }
        },
        {
            to: "/corners", label: "Student's Corner", onMouseEnter: () => {
                setIsShowHover(true)
            }
        },
        {
            to: "https://matricula.co.in/web-stories/", label: "Visual Stories", onMouseEnter: () => {
                setIsShowHover(false)
            }
        },
    ];

    const donationUrl = "https://pages.razorpay.com/donation-matricula";
    const matriculaurl = "https://matricula.co.in";
    const donationPage = () => {
        window.location.assign(donationUrl);
    }


    return (
        <>
            {/* ---- for desktop----*/}
            <div className="desktop-header">
                <header>
                    <div className="navbar-logo">
                        <NavLink to="https://matricula.co.in/"><img src="/assets/cropped-matricula_logo.png" alt="nav logo" loading='lazy' /></NavLink>
                    </div>
                    <nav>
                        <ul className={"navbar-list"}>
                            {navItems.map((item) => (
                                <li key={item.to}>
                                    <NavLink to={item.to} className={"nav-link"} onMouseEnter={() => {
                                        item.onMouseEnter()
                                    }}>
                                        {item.label}
                                    </NavLink>
                                </li>
                            ))}
                        </ul>
                    </nav>
                    <div className="nav-btns">
                        <button className="btn-donate" onClick={donationPage}>Donate now</button>
                        <div className="btn-bars" onClick={toggleNav}> {isMobileNavShow ? <MdCancelPresentation /> :
                            <HiBars4 />} </div>
                    </div>
                </header>
                {isShowHover &&
                    <div className="sub-header">
                        <div className="navbar-list">
                            {subHeaderMenu.map((ele, id) => (
                                <li key={id} onClick={() => {
                                    setIsShowHover(false)
                                }}><NavLink to={ele.link} className={"nav-link"}>{ele.label}</NavLink></li>
                            ))}
                        </div>
                    </div>
                }

                {/*---- for mobile device --------*/}
                {isMobileNavShow &&
                    <div className="navbar-mobile">
                        <ul className="navbar-items-mobile">
                            <li onClick={toggleNav}><NavLink to={"/"}>Home</NavLink></li>
                            <li onClick={toggleNav}><NavLink to={"https://mantra.matricula.co.in/"}>MANTRA</NavLink></li>
                            <li onClick={toggleNav}><NavLink to={"https://mts.matricula.co.in/"}>MTS</NavLink></li>
                            <li onClick={toggleNav}><NavLink to={"https://spokenenglish.matricula.co.in/"}>Spoken English</NavLink></li>
                            <li onClick={toggleNav}><NavLink to={"https://courses.matricula.co.in/"}>Courses</NavLink></li>
                            <li onClick={() => setIsDropDownShow(!isDropdownShow)}>
                                <NavLink to={"https://matricula.co.in/students-corner/"}>Student's Corner</NavLink>
                                <i className={"dropdown-icon"}> {isDropdownShow ? <FaCaretUp /> : <FaCaretDown />} </i>
                            </li>
                            {isDropdownShow &&
                                <ul className={"dropdown"}>
                                    <li><NavLink to={"https://matricula.co.in/category/blog/biology/"}>Biology</NavLink></li>
                                    <li><NavLink to={"https://matricula.co.in/category/blog/maths/"}>Maths</NavLink></li>
                                    <li><NavLink to={"https://matricula.co.in/category/blog/english/"}>English</NavLink></li>
                                    <li><NavLink to={"https://matricula.co.in/category/blog/chemistry/"}>Chemistry</NavLink></li>
                                    <li><NavLink to={"https://matricula.co.in/category/blog/physics-blog/"}>Physics</NavLink></li>
                                    <li><NavLink to={"https://matricula.co.in/category/blog/history/"}>History</NavLink></li>
                                </ul>
                            }
                            <li onClick={() => setIsDropDownShow2(!isDropdownShow2)}>
                                <NavLink to={"/"}>News</NavLink>
                                <i className={"dropdown-icon"}> {isDropdownShow2 ? <FaCaretUp /> : <FaCaretDown />}</i>
                            </li>
                            {isDropdownShow2 &&
                                <ul className={"dropdown"}>
                                    <li><NavLink to={"https://matricula.co.in/category/news-events/educational-law-updates/"}>Educational Law Updated</NavLink></li>
                                    <li><NavLink to={"https://matricula.co.in/category/news-events/exam-updates/"}>Exam Updates</NavLink></li>
                                </ul>
                            }
                            <li onClick={toggleNav}><NavLink to={"https://matricula.co.in/announcements/"}>Announcement</NavLink></li>
                            <li onClick={toggleNav}><NavLink to={"https://matricula.co.in/register/"}>Sign Up</NavLink></li>
                            <li onClick={toggleNav}><NavLink to={"https://matricula.co.in/login/"}>Login</NavLink></li>
                        </ul>
                    </div>
                }
            </div>
        </>
    );
};

export default MatriculaNavbar;