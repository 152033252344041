import React from 'react';
import {
  BrowserRouter,
  Route,
  Routes,
} from "react-router-dom";
import DonationBody from '../pages/Donation-body';
import Contributers from '../pages/Contributers';
import { Navbar } from '../pages/Navbar';
import SuccessStories from '../pages/SuccesStory';
import { CONTRIBUTERS_PAGE, NAVBAR, SUCCESS_STORIES_PAGE } from '../components/PageName';
import ScrollToTop from '../components/scrolltoTop';



const Routers = () => {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route index element={<DonationBody />} />
        {/* <Route path={CONTRIBUTERS_PAGE} element={<Contributers />} /> */}
        <Route path={NAVBAR} element={<Navbar />} />
        {/* <Route path={SUCCESS_STORIES_PAGE} element={<SuccessStories />} /> */}
      </Routes>
    </BrowserRouter>
  );
}

export default Routers;
