
import React, { useState, useEffect } from 'react';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';
import Splide from '@splidejs/splide';
import './style.css';

// import success1 from '../../assets/success-1.png';
// import successlogo from '/assets/card-logo.png';
import Contributers from '../Contributers';

const SuccessStories = () => {
    const initialSuccessStories = [
        {
            id: 1,
            image: '/assets/successStory/Aryan01.jpg',
            organization: 'Empower Young Minds: Breaking the Chains of Circumstance',
            logo: '/assets/card-logo.png',
            text: "Ananya's academic victory",
            description: 'Ananya, a bright student from a small village, excelled in her studies with the support of "Matricula Education". The organization provided them with scholarships, textbooks, and access to tuition. Today, Ananya is fulfilling her dream of becoming a teacher, and her success story is an inspiration to many in her community.'
        },
        {
            id: 1,
            image: '/assets/successStory/Vikas01.jpg',
            organization: 'Empower Young Minds: Breaking the Chains of Circumstance',
            logo: '/assets/card-logo.png',
            text: "Raj's journey from struggle to strength",
            description: 'Raj faced financial challenges but found hope through the "Matricula Education Hopeful Horizons Fund". This fund provided him with financial assistance for his higher education. With determination and support, Raj completed his engineering degree and is now working as a successful software engineer, breaking the cycle of poverty in his family.'
        },
        {
            id: 1,
            image: '/assets/successStory/Neha01.jpg',
            organization: 'Empower Young Minds: Breaking the Chains of Circumstance',
            logo: '/assets/card-logo.png',
            text: "Priya's artistic progress",
            description: "Priya, a talented artist, discovered her potential through the “Art Avenues for Aspirations” program of Matricula Education. This initiative provided them with art supplies, guidance, and experience with art programs. Priya's artwork gained recognition, and she is now pursuing a career as a professional artist, turning her passion into a thriving profession."
        },
        {
            id: 1,
            image: '/assets/successStory/Aryan.jpg',
            organization: 'Empower Young Minds: Breaking the Chains of Circumstance',
            logo: '/assets/card-logo.png',
            text: "Aryan's coding success",
            description: "Aryan, a young enthusiast with a keen interest in technology, benefited from Matricula Education's “Coding Champions for Children”  initiative. This program equipped them with coding skills and guidance. Aryan's proficiency in coding inspired him to develop innovative apps, and he now works as a junior software developer, realizing his dream in the tech industry."
        },
        {
            id: 1,
            image: '/assets/successStory/Neha.jpg',
            organization: 'Empower Young Minds: Breaking the Chains of Circumstance',
            logo: '/assets/card-logo.png',
            text: "Neha's victory in learning the language",
            description: "Coming from a rural background, Neha mastered the language through “Matricula Education's Language Learners' League”. This initiative provided language learning resources and support. Now fluent in multiple languages, Neha has secured a job as a language interpreter, breaking barriers and opening up new opportunities for herself."
        },
        {
            id: 1,
            image: '/assets/successStory/Vikas.jpg',
            organization: 'Empower Young Minds: Breaking the Chains of Circumstance',
            logo: '/assets/card-logo.png',
            text: "Vikas's sports achievements",
            description: "Vikas, a talented athlete, flourished with the support of “Matricula Education's Sports Stars for Scholars”. This program gave them access to sports equipment, coaching, and sporting events. Vikas won several championships and is now a role model in his community, encouraging other youngsters to pursue their passion for sports."
        },
        {
            id: 1,
            image: '/assets/successStory/Sneha.jpg',
            organization: 'Empower Young Minds: Breaking the Chains of Circumstance',
            logo: '/assets/card-logo.png',
            text: "Sneha's Green Patronage",
            description: "Passionate about the environment, Sneha found her identity through “Green Guardians for Growth of Matricula Education”. This initiative supported their environmental projects and provided education on sustainability. Sneha is now an environmental activist, taking initiatives to promote eco-friendly practices in her community"
        },

    ];


    const [successStories, setSuccessStories] = useState(initialSuccessStories);

    useEffect(() => {
        const splide = new Splide('.splide', {
            type: 'loop',
            drag: 'free',
            snap: true,
            perPage: 3,
            breakpoints: {
                800: {
                    perPage: 2,
                },
                600: {
                    perPage: 1,
                },
            },
            arrows: true,
        });

        splide.mount();

        return () => {
            splide.destroy();
        };
    }, []);

    return (
        <>
            <div className='total-success-container'>
                <div className='header-and-grid-container'>
                    <div className='header'>
                        <h1>Success <span>stories</span></h1>
                        <div className='success-grid-container splide'>
                            <div className='splide__track'>
                                <ul className='splide__list'>
                                    {successStories.map((story, index) => (
                                        <li key={index} className='splide__slide single-success-grid'>
                                            <img src={story.image} alt='success-pic' loading='lazy' />
                                            <p className='success-text'>{story.text}</p>
                                            {/* <div className='success-logo-with-details'>
                                                <img src={story.logo} alt='success-logo' />
                                                <p>{story.organization}</p>
                                            </div> */}
                                            <p className='success-description'>{story.description}</p>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default SuccessStories;
