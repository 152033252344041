// Import necessary libraries and components
import React, { useState, useEffect, useMemo } from "react";
import "./style.css";
import Contributers from "../Contributers";
import SuccessStories from "../SuccesStory";
import { useGlobalHook } from "../../Hooks/apicontext";
import Footer from "../Footer";
import MatriculaNavbar from "../MatriculaNav";
// import ReactGA from "react-ga";


const DonationBody = () => {

  // useEffect(() => {
  //   ReactGA.pageview(window.location.pathname);
  // }, [])


  const { titleChange } = useGlobalHook();
  useEffect(() => {
    titleChange("Donation");
  }, []);

  const initialCardData = [
    {
      image: "/assets/cause/Embracing Literacy with Love.jpg",
      title: "Embracing Literacy with Love",
      description:
        "It is a compassionate initiative dedicated to helping underprivileged children who lack the resources to purchase their study materials. By providing free access to books to these young minds, the program aims to foster a love of learning and empower them to overcome educational barriers. Together, through the simple act of sharing literature, we aspire to inspire hope and make a positive impact on the educational journey of these deserving children.",
      width: "17.5%",
      amountRaised: "70000",
      goal: "400000",
      supporter: "10",
    },
    {
      image: "/assets/cause/Ink of Inspiration Drive.jpg",
      title: "Ink of Inspiration Drive",
      description:
        "It will help deprived children who do not have the means to purchase their copies and stationery for studies. By providing essential tools like pens, pencils, and notebooks, the campaign aims to inspire young minds and remove barriers to education. Through the generosity of donors, we want to ignite a sense of hope and empower these children to embrace the transformative power of education, one effort at a time.",
      width: "6%",
      amountRaised: "30000",
      goal: "500000",
      supporter: "12",
    },
    {
      image: "/assets/cause/Love in Every Lunchbox.jpg",
      title: "Love in Every Lunchbox",
      description:
        "It is a poignant initiative dedicated to ensuring that every child gets the proper nutrition needed for a healthy future. By providing nutritious food with care, this program goes beyond sustenance – it is a gesture of love and compassion. Through this initiative, we aim to nourish not only the bodies but also the hearts of underprivileged children, reminding them that they are cared for and valued. Join us in making a meaningful impact, one lunchbox full of love at a time.",
      width: "10%",
      amountRaised: "20000",
      goal: "200000",
      supporter: "17",
    },
    {
      image: "/assets/cause/Tender Minds Tutoring Program.jpg",
      title: "Tender Minds Tutoring Program",
      description:
        "This initiative provides additional tuition and support needed for children's studies. Designed to meet the specific needs of each child, the program aims to foster academic growth and a love of learning. Join us in making a meaningful impact on young minds by supporting this initiative and ensuring that every child has the opportunity to succeed.",
      width: "14.29%",
      amountRaised: "50000",
      goal: "350000",
      supporter: "9",
    },
    {
      image: "/assets/cause/Crack the Code of Dream Colleges.jpg",
      title: "Crack the Code of Dream Colleges",
      description:
        "This is an important initiative providing the necessary additional tuition and support to students aspiring to crack JEE. With personalized support, this program aims to empower young minds to tackle the complexities of entrance exams, thereby turning their dreams of prestigious colleges into reality. Support us in shaping a bright educational future for these ambitious students.",
      width: "2.22%",
      amountRaised: "10000",
      goal: "450000",
      supporter: "7",
    },
    {
      image: "/assets/cause/Caring for Little Petals.jpg",
      title: "Caring for Little Petals",
      description:
        "This is a heartfelt initiative dedicated to ensuring the hygiene and well-being of young girls. This program provides essential support, creating a nurturing space where girls can blossom with confidence and dignity. Help us create a future where every girl feels nurtured and empowered.",
      width: "5%",
      amountRaised: "30000",
      goal: "600000",
      supporter: "13",
    },
    {
      image: "/assets/cause/Spark of Sportsmanship.jpg",
      title: "Spark of Sportsmanship",
      description:
        "It lights the dreams of young athletes, giving them a chance to participate and excel in various sports. This initiative not only fosters their passion but also inculcates important values like teamwork and resilience. Your support helps create a future where the spark of sportsmanship becomes a guiding light for these aspiring athletes, fostering a love of sports and paving the way to success.",
      width: "16.67%",
      amountRaised: "100000",
      goal: "600000",
      supporter: "22",
    },
    {
      image: "/assets/cause/Academic Aspirations Assistance.jpg",
      title: "Academic Aspirations Assistance",
      description:
        "This is an initiative that turns dreams into reality by providing necessary support to children pursuing higher education. Your contribution not only provides financial support but also symbolizes a commitment to nurturing aspirations and opening up a world of possibilities. Join us in fostering a future where every child's educational dreams are fulfilled with hope, resilience, and the promise of a brighter tomorrow.",
      width: "15%",
      amountRaised: "90000",
      goal: "600000",
      supporter: "8",
    },
    {
      image: "/assets/cause/Vocational Virtue Aid.jpg",
      title: "Vocational Virtue Aid",
      description:
        "A heartfelt initiative providing the transformative gift of business training to children. More than mere skills, it gives rise to virtues, providing the path to self-reliance and perfection. Your support shapes a future where every child carves their destiny with dignity and achievement. Join us in promoting hope and virtue to these ambitious individuals.",
      width: "13.46%",
      amountRaised: "70000",
      goal: "520000",
      supporter: "19",
    },
    {
      image: "/assets/cause/wings of Ambition Aid.jpeg",
      title: "Wings of Ambition Aid",
      description:
        "This initiative provides vital support for job examinations. Beyond resources, it symbolizes the wings that give flight to dreams. Your support becomes a ray of hope, helping individuals move toward career goals. Join us in turning ambitions into success.",
      width: "16%",
      amountRaised: "40000",
      goal: "250000",
      supporter: "20",
    },
  ];
  const donationUrl = "https://pages.razorpay.com/donation-matricula";
  const thousanddonateUrl = "https://rzp.io/l/donation-1000-rs";
  const customDonateUrl = "https://rzp.io/l/donation-matricula";
  const twothousandUrl = "https://rzp.io/l/donation-2000-rs";
  const fivethouDonateUrl = "https://pages.razorpay.com/donation-matricula-5000-rs";

  const monthlythouDonate = "https://rzp.io/i/YHoAKxL9Mr";
  const monthlytwothouDonate = "https://rzp.io/i/aFe3pktdp";
  const monthlyfivethouDonate = "https://rzp.io/i/d3mepkIPyH";

  const monthlypaymap = useMemo(
    () => ({
      1000: monthlythouDonate,
      2000: monthlytwothouDonate,
      5000: monthlyfivethouDonate,
    }),
    []
  );
  const singlepaymap = useMemo(
    () => ({
      1000: thousanddonateUrl,
      2000: twothousandUrl,
      5000: fivethouDonateUrl,
      others: customDonateUrl,
    }),
    []
  );

  const [donationAmount, setDonationAmount] = useState("1000");
  const [activeAmount, setActiveAmount] = useState("1000");

  const handleAmountClick = (amount) => {
    setDonationAmount(amount === "others" ? "others" : amount);
    setActiveAmount(amount);
  };

  const handleInputChange = (e) => {
    setDonationAmount(e.target.value);
  };

  const makeDonation = () => {
    let currentactiveUrl =
      activeClass === "Monthly" ? monthlypaymap[donationAmount] : singlepaymap[donationAmount];
    console.log(currentactiveUrl);
    window.location.assign(currentactiveUrl);
    // ReactGA.event({
    //   category: "Donation",
    //   action: "Make Donation",
    //   label: currentactiveUrl
    // });
  };

  const [cardData, setCardData] = useState(initialCardData);
  const [visibleCards, setVisibleCards] = useState(3);

  const handleShowMore = () => {
    setVisibleCards((prevVisibleCards) => prevVisibleCards + 16);
  };

  const [activeClass, setActiveClass] = useState("Single");

  const handleClassClick = (className) => {
    setActiveClass(className);
    console.log(className);
  };

  // const [formData, setFormData] = useState({
  //   email: "",
  //   password: "",
  // });

  // const handleInputChangeDonator = (e) => {
  //   const { name, value } = e.target;
  //   setFormData({
  //     ...formData,
  //     [name]: value,
  //   });
  // };

  // const handleLoginClick = () => {
  //   console.log({ formData });
  //   window.location.assign(donationUrl);
  // };

  const donationredirect = () => {
    // window.location.assign(customDonateUrl);
    const donationForm = document.getElementById("donation");
    if (donationForm) {
      donationForm.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <>
      <MatriculaNavbar />
      <div className="total-heading-background">
        <div className="donation-total-content-container">
          <div className="right-content">
            <div className="donation-text">
              <h1>Support us with your donation</h1>
              <p>
                At Matricula Education, we are dedicated to making quality education accessible to all. Matricula serves as an important catalyst, mobilizing resources to assist children in need.
              </p>
              <p>
                Join us in our mission to transform lives through education. Your generous support is essential to cover our operating costs and the success of our educational projects and campaigns. Contribute today to be part of a life-changing movement.
              </p>
              <p id="donation">
                If you would like to increase your support for multiple causes in India focused on increasing access to education for youth, contact us at donate@matricula.co.in. We assure you that we will responsibly allocate your donation to the projects and campaigns that need it most.
              </p>
              <p>
                Let us together create a bright future through education.
              </p>
            </div>

            <div className="donation-pay-content">
              <div className="pay-division-section">
                <p
                  style={{
                    background: activeClass === "Single" ? "#f1f1f1" : "",
                  }}
                  onClick={() => handleClassClick("Single")}
                >
                  Single
                </p>
                <p
                  style={{
                    background: activeClass === "Monthly" ? "#f1f1f1" : "",
                  }}
                  onClick={() => handleClassClick("Monthly")}
                >
                  Monthly
                </p>
              </div>
            </div>
            <div className="donation-form">
              <div className="donation-amount">
                <div
                  className={`wrapper-div ${activeAmount === "1000" ? "active" : ""
                    }`}
                  onClick={() => handleAmountClick("1000")}
                  style={{
                    backgroundColor: activeAmount === "1000" ? "#f57324" : "",
                  }}
                >
                  <p>₹</p>
                  <p>1000</p>
                </div>
                <div
                  className={`wrapper-div ${activeAmount === "2000" ? "active" : ""
                    }`}
                  onClick={() => handleAmountClick("2000")}
                  style={{
                    backgroundColor: activeAmount === "2000" ? "#f57324" : "",
                  }}
                >
                  <p>₹</p>
                  <p>2000</p>
                </div>
                <div
                  className={`wrapper-div ${activeAmount === "5000" ? "active" : ""
                    }`}
                  onClick={() => handleAmountClick("5000")}
                  style={{
                    backgroundColor: activeAmount === "5000" ? "#f57324" : "",
                  }}
                >
                  <p>₹</p>
                  <p>5000</p>
                </div>
                {activeClass !== "Monthly" && (
                  <div
                    className={`wrapper-div ${activeAmount === "others" ? "active" : ""
                      }`}
                    onClick={() => handleAmountClick("others")}
                    style={{
                      backgroundColor: activeAmount === "others" ? "#f57324" : "",
                    }}
                  >
                    <p>₹</p>
                    <p>Others</p>
                  </div>
                )}
              </div>
              <div className="donation-input-box">
                {donationAmount === "others" ? (
                  ""
                ) : (
                  <input
                    type="number"
                    placeholder="Amount in INR"
                    value={donationAmount}
                    onChange={handleInputChange}
                    readOnly
                  />
                )}
              </div>
              <button className="amount-button" onClick={makeDonation}>
                Donate
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="total-support-section">
        <div className="header">
          <h1>Support a Cause</h1>
          <div className="single-line"></div>
        </div>
        <div className="grid-container">
          {cardData.slice(0, visibleCards).map((card, index) => (
            <div key={index} className="single-card">
              <img
                src={card.image}
                alt={`pic-${index}`}
                className="image-decoration"
                loading="lazy"
              />
              {/* <div className='relative-content'>Income Generation</div> */}
              <div className="card-details">
                <h1>{card.title}</h1>

                <div className="logo-container-with-details">
                  <p className="logo-text">{card.description}</p>
                </div>
                <div className="donate-details-container">
                  <div className="price-and-details">
                    <p className="pricing-text">₹{card.amountRaised} </p>
                    <p className="goal-text">
                      raised of ₹{card.goal} goal
                    </p>
                  </div>
                  <div className="achiver-slider">
                    <span style={{ width: card.width }}></span>
                  </div>
                  <div className="supporter-text">
                    <p>On going</p>
                    <p>{card.supporter} supporters</p>
                  </div>
                  <div className="card-button">
                    <button
                      className="donate-button"
                      onClick={donationredirect}
                    >
                      Donate
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
        {visibleCards < cardData.length && (
          <div className="show-more-button">
            <button onClick={handleShowMore}>Show More</button>
          </div>
        )}
      </div>
      <Contributers />
      <SuccessStories />
      <Footer />
    </>
  );
};

export default DonationBody;
