import { useEffect } from 'react';
import './App.css';
import { AppProvider } from './Hooks/apicontext';
import Routers from './router';
// import ReactGA from "react-ga";



function App() {
  // ReactGA.initialize('G-NJVG27Q5CY');
  useEffect(() => {
    const handleContextMenu = (event) => {
      event.preventDefault();
    };
    document.addEventListener('contextmenu', handleContextMenu);
    return () => {
      document.removeEventListener('contextmenu', handleContextMenu);
    };
  }, []);
  return (
    <AppProvider>
      <Routers />
    </AppProvider>
  );
}

export default App;


