import React from 'react'
import './style.css';
import { FaFacebook } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa";

const Footer = () => {
    return (
        <>
            <div className='footer-background'>
                <div className='footer-max-width'>
                    <div className='footer-main-container'>
                        <div className='email-container'>
                            <p className='footer-subs-text'>Subscribe To Our Newsletter</p>
                            <input type='email' className='input-class' />
                            <button type='button' className='footer-subscribe-button'>Subscribe Now</button>
                        </div>
                        <div className='all-footer-link'>
                            <div className='grid-column-style'>
                                <p><a href='https://mantra.matricula.co.in/'>MANTRA</a></p>
                                <p><a href='https://mts.matricula.co.in/'>MTS</a></p>
                                <p><a href='https://spokenenglish.matricula.co.in/'>Spoken English</a></p>
                                <p><a href='https://courses.matricula.co.in/'>Courses</a></p>
                            </div>
                            <div className='grid-column-style'>
                                <p><a href='https://matricula.co.in/students-corner/'>Student’s Corner</a></p>
                                <p><a href='https://matricula.co.in/students-corner/'>All</a></p>
                                <p><a href='https://matricula.co.in/category/blog/biology/'>Biology</a></p>
                                <p><a href='https://matricula.co.in/category/blog/chemistry/'>Chemistry</a></p>
                                <p><a href='https://matricula.co.in/category/blog/english/'>English</a></p>
                                <p><a href='https://matricula.co.in/category/blog/geography/'>Geography</a></p>
                                <p><a href='https://matricula.co.in/category/blog/history/'>History</a></p>
                                <p><a href='https://matricula.co.in/category/blog/maths/'>Maths</a></p>
                                <p><a href='https://matricula.co.in/category/blog/physics-blog/'>Physics</a></p>
                                <p><a href='https://matricula.co.in/category/blog/ncert/'>NCERT</a></p>
                            </div>
                            <div className='grid-column-style'>
                                <p><a href='https://matricula.co.in/news-and-events/'>News & Events</a></p>
                                <p><a href='https://matricula.co.in/announcements/'>Announcements</a></p>
                                <p><a href='https://matricula.co.in/about-us/'>About Us</a></p>
                                <p><a href='https://matricula.co.in/contact-us/'>Contact Us</a></p>
                                <p><a href='https://matricula.co.in/web-stories/'>Visual Stories</a></p>
                            </div>
                            <div className='grid-column-style'>
                                <p><a href='https://matricula.co.in/terms-and-conditions/'>Terms & Conditions</a></p>
                                <p><a href='https://matricula.co.in/privacy-policy/'>Privacy Policy</a></p>
                                <p><a href='https://matricula.co.in/refund-policy/'>Refund Policy</a></p>
                                <p><a href='https://matricula.co.in/affiliate-disclosure/'>Affiliate Disclosure</a></p>
                                <p><a href='https://matricula.co.in/disclaimer/'>Disclaimer</a></p>
                                <p><a href='https://www.linkedin.com/company/matricula/'>Careers</a></p>
                            </div>
                            <div className='grid-column-style'>
                                <div className='line-view-with-content'>
                                    <div className='single-line'></div>
                                    <div className='flex-column-style'>
                                        <p className='social-text'>Our Socials</p>
                                        <div className='icon-flex'>
                                            <a href='https://www.facebook.com/matricula.edu/'><FaFacebook className='icon-size' /></a>
                                            <a href='https://twitter.com/i/flow/login?redirect_after_login=%2Fmatricula_edu'><FaTwitter className='icon-size' /></a>
                                            <a href='https://www.youtube.com/@MatriculaEducation'><FaYoutube className='icon-size' /></a>
                                            <a href='https://www.linkedin.com/company/matricula/'><FaLinkedinIn className='icon-size' /></a>
                                        </div>
                                        <div className='information'>
                                            <p>Email: <a href='mailto:hey@matricula.co.in'>hey@matricula.co.in</a></p>
                                            <div className='contact-information'>
                                                <p>Contact No:</p>
                                                <div>
                                                    <p><a href='tel:+918100482638'>+91 81004 82638</a></p>
                                                    <p><a href='tel:+917439709486'>+91 74397 09486</a></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className='copyright-text'>
                            <p>Copyright © 2017 – 2023 Roasted Sage Private Limited | Developed by  <a href="https://serein.io"><span>Serein.io</span></a></p>
                        </div>
                    </div>
                </div>
            </div >
        </>
    )
}

export default Footer
