import { useState } from "react";
import { NavLink } from "react-router-dom";
import './style.css'
// import navlogo from '../../assets/nav-logo.png'
// import loginicon from '../../assets/loginicon.png'
export const Navbar = () => {

    const [isShow, setIsShow] = useState(false);
    const navToggle = () => {
        setIsShow(!isShow);
    }

    return (
        <>
            <header className="headertop">
                <div className="container">
                    <div className="logo">
                        <NavLink to={""}>
                            <img src='/assets/nav-logo.png' alt="INDIAdonates: Trusted and Ethical Donation Platform in India" />
                        </NavLink>
                    </div>
                    <div className="toplinks">
                        <ul>
                            <li className="desktoplink"><NavLink to={""}><i className="fa fa-facebook"></i></NavLink></li>
                            <li className="desktoplink"><NavLink to={""}><i className="fa fa-twitter"></i></NavLink></li>
                            <li className="desktoplink"><NavLink to={""}><i className="fa fa-instagram"></i></NavLink></li>
                            <li className="desktoplink"><NavLink to={""}><i className="fa fa-youtube"></i></NavLink></li>
                            <li className="mobilelink donatebtn"><NavLink to={""} href="">Donate Now</NavLink></li>
                            <li className="mobilelink loginbtn"><NavLink to={"/"} className="droplog">Login <img
                                src='/assets/loginicon.png' alt="INDIAdonates Login" />
                            </NavLink>
                                <ul className="logindrop">
                                    <li><NavLink to={"/"} >Donor</NavLink></li>
                                    <li><NavLink to={"/"} >NGO Login </NavLink></li>
                                </ul>
                            </li>
                            <li className="loginbtn desktoplink"><NavLink to={""}>NGO Login</NavLink></li>
                            <li className="desktoplink donerbtn"><NavLink to={""}>Donor</NavLink></li>

                        </ul>
                    </div>

                    <div className={isShow ? "navicon active" : "navicon"} onClick={navToggle}><span></span></div>
                    <div className={isShow ? "topmenu showmenu" : "topmenu"}>
                        <div className="mobilelink mobilelogo"><NavLink to={"/"}><img src='/assets/nav-logo.png'
                            alt="INDIAdonates: Donation Platform in India" /></NavLink>
                        </div>
                        <ul>
                            <li><NavLink to={""}>About Us</NavLink></li>
                            <li><NavLink to={""}>Knowledge Hub</NavLink></li>
                            <li><NavLink to={""}>NGO Sign Up</NavLink></li>
                            <li><NavLink to={""}>Partnerships</NavLink></li>
                            <li className="desktoplink donatebtn"><NavLink to={""}>Donate Now</NavLink></li>
                            <li className="mobilelink"><NavLink to={""}>NGO Login </NavLink></li>
                            <li className="mobilelink"><NavLink to={""}>Donor</NavLink></li>
                        </ul>
                    </div>

                    <div className="searchbg">
                        <span className="searchicon"><i className="fa fa-search"></i></span>
                        <div className="searchbox">
                            <form name="search" action="/all-projects" method="get" encType="multipart/form-data">
                                <label>
                                    <input type="text" name="k" placeholder="SEARCH" />
                                </label>
                                <input type="submit" value="GO" className="searchbtn" />
                            </form>
                        </div>
                    </div>
                </div>
            </header>
        </>
    )
}